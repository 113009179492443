/*
  drm = {
    widevine: {
      LA_URL: <String_url>,
    }
  }
*/
export function createPlayerSource(streamUrl, drm) {
  let re = /(?:\.(m3u8|mpd))/;
  let ext = re.exec(streamUrl)[1];
  const newSource = {};
  if (ext === "m3u8") {
    newSource.hls = streamUrl;
    newSource.dash = undefined;
  }
  if (ext === "mpd") {
    newSource.hls = undefined;
    newSource.dash = streamUrl;
  }

  if (drm) {
    newSource.drm = drm;
  }

  return newSource;
}
