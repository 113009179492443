import React from "react";
import styles from "./MetacastLogo.scss";
import classNames from "classnames";
import { ReactComponent as TokIcon } from "../../../react-components/icons/tok-logo.svg";
import { MantineProvider, Text, useMantineTheme } from "@mantine/core";
import hubsUITheme from "../../hubsUI/theme/hubs-ui-theme";

function _MetacastLogo() {
  const theme = useMantineTheme();
  return (
    <div className={classNames(styles.metacastLogoContainer)}>
      <TokIcon width={60} height={60} />
      <Text
        variant="gradient"
        gradient={{ from: theme.colors.hubsBlue[6], to: theme.colors.hubsBlue[5], deg: 45 }}
        fw={700}
        fz={36}
        sx={{
          textShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)"
        }}
      >
        TOK
      </Text>
    </div>
  );
}

export function MetacastLogo() {
  return (
    <MantineProvider theme={hubsUITheme} withGlobalStyles withNormalizeCSS>
      <_MetacastLogo />
    </MantineProvider>
  );
}
