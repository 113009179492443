import { produce } from "immer";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
//import { RoleTypes } from "@toktv/wwm-sdk"; NOTE: DO NOT USE RoleTypes from SDK in this file. It will break `npm run login`
const RoleTypes = Object.freeze({
  FULL: "full",
  COMPANION: "companion",
  TV: "tv"
});

const useAppStore = create(
  devtools(
    subscribeWithSelector((set, get) => ({
      role: null,
      wwmEnabled: false,
      reticulumEnabled: false,
      dialogEnabled: false,
      /* Global loading state of the application */
      loading: {
        progress: 0,
        messageId: "metacast.loading-screen-modal.loader.message.loading",
        defaultMessage: "Loading TOK..."
      },
      /* Gloabl error state of the application */
      error: null,

      /* Presence */
      presenceDrawerOpened: false,

      /* Fullscreen application */
      isFullscreen: false,
      setRole: role => {
        set(
          produce(draft => {
            draft.role = role;
          })
        );
      },
      setWwmEnabled: () => {
        set(
          produce(draft => {
            draft.wwmEnabled = true;
          })
        );
      },
      setReticulumEnabled: () => {
        set(
          produce(draft => {
            draft.reticulumEnabled = true;
          })
        );
      },
      setDialogEnabled: () => {
        set(
          produce(draft => {
            draft.dialogEnabled = true;
          })
        );
      },
      setLoading: (progress, messageId, defaultMessage) => {
        set(
          produce(draft => {
            // Once initial loading is over is not possible to set loading again. If needed, consider a startLoading() action
            if (draft.loading) {
              draft.loading.progress = progress;
              draft.loading.messageId = messageId || draft.loading.messageId;
              draft.loading.defaultMessage = defaultMessage || draft.loading.defaultMessage;
            }
          })
        );
      },
      loadingComplete: () => {
        set(
          produce(draft => {
            draft.loading = null;
          })
        );
      },
      setErrorMessage: (message, options = {}) => {
        set(
          produce(draft => {
            draft.error = { ...options, message };
            draft.loading = null;
            draft.wwmEnabled = false;
            draft.reticulumEnabled = false;
            draft.dialogEnabled = false;
            draft.presenceLocation = null;
          })
        );
      },
      openPresenceDrawer: () => {
        set(
          produce(draft => {
            draft.presenceDrawerOpened = true;
          })
        );
      },
      closePresenceDrawer: () => {
        set(
          produce(draft => {
            draft.presenceDrawerOpened = false;
          })
        );
      },
      setIsFullscreen: isFullscreen => {
        set(
          produce(draft => {
            draft.isFullscreen = isFullscreen;
          })
        );
      },
      isCompanion: () => {
        const { role } = get();
        return role === RoleTypes.COMPANION;
      }
    })),
    { name: "appStore", enabled: true }
  )
);

export default useAppStore;

export function setLoadingProgress(progress, { messageId, defaultMessage } = {}) {
  useAppStore.getState().setLoading(progress, messageId, defaultMessage);
}
