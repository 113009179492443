export async function resolveWwmVrVideo(base_url) {
  if (base_url.match(/wwmvrvideo$/)) {
    let res = await fetch(base_url);
    if (res.redirected) return res.url;
  }

  return base_url;
}

/* Check if the provided url is for dash or hls */
export async function isVideoUrl(url) {
  let isValid = /\.mpd/.test(url) || /\.m3u8/.test(url);
  if (!isValid) {
    let res = await fetch(url, { mode: "no-cors" });
    return /\.mpd/.test(res.url) || /\.m3u8/.test(res.url);
  }
  return isValid;
}

export async function validateStreamUrl(streamUrl) {
  const urlObj = new URL(streamUrl);
  let isValid = await isVideoUrl(streamUrl);
  if (!isValid) {
    throw new Error("Invalid stream url. It must contain or redirect to an url with .mpd or .m3u8 extension.");
  }
}
