import { createAndInitializeWwmClient } from "../../wwm-client";
import { createPlayerSource } from "../bitmovin-utils";
import { compressContentObject } from "../compress-utils";
import { createNewHub, isLocalClient } from "../hubs/phoenix-utils";
import { endpointReducer } from "./m10-utils";
import { validateStreamUrl } from "../url-utils";
import { WwmRoomTypes } from "@toktv/wwm-sdk";

function generateUrlMetacastParam(hostToken, roomId, streamUrl, licenseUrl, userProps, shortUrl, roomType, hostLeid) {
  const drm = licenseUrl ? { widevine: { LA_URL: licenseUrl } } : undefined;
  const videoSrc = createPlayerSource(streamUrl, drm);
  const payload = {
    hostToken,
    roomId,
    videoSrc,
    userProps,
    shortUrl,
    provider: "minerva",
    version: 10,
    roomType,
    hostLeid
  };
  const metacast = compressContentObject(payload);
  return `metacast=${metacast}`;
}

/* This function will be used only in MinervaAuthForm. As for requirements, all minerva users
* must be redirected to Metacast 2D.
*/
export const createWwmRoomAndRedirectToNewHub = async (data, toHubs, onError) => {
  let client;
  try {
    if (data.token && typeof data.token === "string") {
      const wwmToken = data.token.trim();
      let { streamUrl, licenseUrl, minervaEndpoints } = data;
      if (streamUrl) {
        streamUrl = streamUrl.trim();
        await validateStreamUrl(streamUrl);
      } else {
        //DEFAULT VIDEO MUSIC 4 CLIMATE
        streamUrl = "https://media.tok.tv/minerva/STORAGE/a_live/shows/ronaldino_hls/playlist.m3u8";
        //if streamUrl is not provided not need license for default stream.
        licenseUrl = "";
      }

      if (licenseUrl) {
        const urlObj = new URL(licenseUrl);
      }
      const userProps = minervaEndpoints ? endpointReducer(minervaEndpoints) : null;
      const wwmClientOptions = {
        token: wwmToken,
        userProps,
        ...(userProps.edge && { customerEndpoint: userProps.edge + "/xtv-ws-client/api/v1/customer" })
      };

      client = createAndInitializeWwmClient(wwmClientOptions);
      await client.connect();
      const roomType = toHubs ? WwmRoomTypes.ROOM_3D : WwmRoomTypes.ROOM_2D;
      const oldRoom = await client.getRoomIfExists({ roomType, edge: userProps.edge });

      let shortUrl;
      let hubUrl;
      let roomId;
      let hostLeid;

      if (!oldRoom.room) {
        const hub = await createNewHub();
        console.log("[HUB]: ", hub);
        hubUrl = hub.url;
        let viewerBaseUrl;

        if (toHubs) {
          viewerBaseUrl = isLocalClient() ? location.origin + hubUrl : hubUrl;
        } else {
          hubUrl = viewerBaseUrl = location.origin + `?hub_id=${hub.hub_id}`;
        }

        const createRes = await client.createRoom({ viewerBaseUrl, roomType, edge: userProps.edge }); // Save url on wwm roomController
        console.log("WWM MQTT Received response for room creation:", createRes);
        shortUrl = createRes.room.shortUrl;
        roomId = createRes.room.roomId;
        hostLeid = createRes.room.hostLeid;
      } else {
        console.log("WWM MQTT Received old room response: ", oldRoom);
        hubUrl = oldRoom.room.viewerUrl;
        shortUrl = oldRoom.room.shortUrl;
        roomId = oldRoom.room.roomId;
        hostLeid = oldRoom.room.hostLeid;
      }

      await client.end();

      const urlMetacastParam = generateUrlMetacastParam(
        wwmToken,
        roomId,
        streamUrl,
        licenseUrl,
        userProps,
        shortUrl,
        roomType,
        hostLeid
      );
      window.open((/[^\s?]+\?/.test(hubUrl) ? `${hubUrl}&` : `${hubUrl}?`) + urlMetacastParam, "_blank");
    }
  } catch (e) {
    console.error("WWM MQTT: ", e);
    await client?.end();
    onError(e.message);
  }
};
