import React from "react";
import { Stack, Title, Text } from "@mantine/core";

export const ErrorPageCodes = Object.freeze({
  410: {
    title: "Invite Link Invalid",
    descritpion: "Sorry, the invite link has expired, or the room has been closed."
  },
  500: {
    title: "Generic Error",
    descritpion: "Sorry, there was a generic error. Maybe you could try again (it works, sometimes…)"
  }
});

export default function ErrorPage({ code }) {
  const error = ErrorPageCodes[code] || ErrorPageCodes[500];

  return (
    <Stack h={"100vh"} align="center" justify="center" spacing="xs">
      <Title order={2}>{error.title}</Title>
      <Text align="center" color="red" maw={490}>
        {error.descritpion}
      </Text>
    </Stack>
  );
}
