import { detect } from "detect-browser";

function _getUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

function _getCookie(key) {
  const name = `${key}=`;
  const all = document.cookie.split(";");

  for (let i = 0; i < all.length; i += 1) {
    let cookie = all[i];

    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
}

function _setCookie(key, value) {
  const date = new Date();
  const oneYear = 365 * 24 * 60 * 60 * 1000;

  date.setTime(date.getTime() + oneYear);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${key}=${value};${expires};path=${window.location.pathname}`;
}

export function getOrCreateDevice() {
  const { os, name, version } = detect();

  let data = {};
  const key = "device";
  let device;

  const oldDevice = _getCookie(key);
  const uuid = _getUuid();

  if (oldDevice) {
    data = oldDevice;
    device = oldDevice;
  } else {
    device = {
      browser: name,
      version: version,
      os: os,
      uuid,
      identifier: uuid,
      deviceInfo: {
        deviceType: "cloud_client",
        appVersion: "1.2.0",
        networkType: "Broadband",
        deviceModel: os,
        deviceName: os,
        deviceOs: os,
        playerType: "Webplayer-widevine",
        dhcp: true,
        casId: uuid,
        mac: uuid
          .split("-")
          .join("")
          .substring(0, 12),
        uuid
      }
    };
    data = JSON.stringify(device);
    _setCookie(key, data);
  }

  return data;
}

export function endpointReducer(minervaEndpoints) {
  return minervaEndpoints.reduce((acc, current) => {
    let { name, baseHttpUrl, baseHttpsUrl } = current;
    let url;
    try {
      url = new URL(baseHttpsUrl || baseHttpUrl);
    } catch (e) {
      console.error(`Invalid URL for ${name}: ${baseHttpsUrl || baseHttpUrl}`);
      return acc;
    }
    return { ...acc, [name]: url.origin };
  }, {});
}
