import { createStyles, Loader as MantineLoader, Stack, Title } from "@mantine/core";
import React, { memo } from "react";

const useStyles = createStyles((theme, { dark }) => ({
  label: {
    color: dark ? theme.white : theme.black
  }
}));

export const Loader = memo(({ label, dark = false, variant = "oval", size = 80, labelOrder = 5, ...others }) => {
  const { classes, theme } = useStyles({ dark });

  return (
    <Stack align="center" {...others}>
      <MantineLoader color={dark ? theme.colors.hubsBlue[5] : theme.colors.hubsBlue[6]} size={size} variant={variant} />
      <Title className={classes.label} order={labelOrder}>
        {label}
      </Title>
    </Stack>
  );
});
