import { HubsUI } from "./theme-constants";

/**
 * @type {import("@mantine/core").MantineTheme}
 */
const hubsUIDarkTheme = {
  colorScheme: "dark",

  fontFamily:
    "Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
  fontFamilyMonospace:
    "Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace",
  headings: {
    fontFamily:
      "Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol"
  },
  colors: {
    [HubsUI.colors.hubsBlue]: [
      "#daf8ff",
      "#ade6ff",
      "#7ed4ff",
      "#4dc3ff",
      "#23b2fe",
      "#1098e5",
      "#0076b3",
      "#005581",
      "#003350",
      "#001220"
    ]
  },
  primaryColor: HubsUI.colors.hubsBlue,
  primaryShade: 5,

  components: {
    Button: {
      defaultProps: {
        radius: "md"
      }
    },
    Notification: {
      defaultProps: {
        radius: "md"
      }
    }
  }
};

export default hubsUIDarkTheme;
