import React, { useEffect } from "react";
import { Button, createStyles, Modal, Stack, Text, Title } from "@mantine/core";
import useAppStore from "../../store/core/app-store";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = createStyles(theme => ({
  body: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export const ErrorScreenActions = Object.freeze({
  RELOAD: {
    id: "metacast.error-screen-modal.actions.reload",
    label: "Reload",
    onClick: () => window.location.reload()
  },
  CLOSE: {
    id: "metacast.error-screen-modal.actions.close",
    label: "Close",
    onClick: () => window.close()
  }
});

export function ErrorScreenModal() {
  const { classes, theme } = useStyles();
  const intl = useIntl();

  const error = useAppStore(state => state.error);

  const errorMessage = error?.message
    ? error.message
    : error?.messageId
      ? intl.formatMessage({ id: error.messageId, defaultMessage: error.defaultMessage }, error.messageValues)
      : null;

  const buttonLabel = error?.action
    ? intl.formatMessage({ id: error.action.id, defaultMessage: error.action.label })
    : intl.formatMessage({ id: ErrorScreenActions.RELOAD.id, defaultMessage: ErrorScreenActions.RELOAD.label });

  return (
    <>
      <Modal
        id="error-screen-modal"
        classNames={{
          body: classes.body
        }}
        opened={error}
        fullScreen
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        centered
        yOffset={0}
        transitionProps={{
          duration: 300
        }}
        overlayProps={{
          opacity: 0
        }}
        trapFocus={false}
      >
        <Stack align="center" spacing={0}>
          <Title order={2}>
            {error?.title ? (
              intl.formatMessage({ id: error.title.id, defaultMessage: error.title.defaultMessage })
            ) : (
              <FormattedMessage id="metacast.error-screen-modal.title" defaultMessage="An error occurred!" />
            )}
          </Title>
          <Text color="red" maw={600}>
            {errorMessage}
          </Text>
          <Button
            mt="lg"
            color="red"
            onClick={error?.action ? error.action.onClick : ErrorScreenActions.RELOAD.onClick}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
