import Store from "../../../storage/store";
import { generateHubName } from "../../../utils/name-generation";
import { getReticulumFetchUrl, isLocalClient } from "../../../utils/phoenix-utils";

/**
 * This function is a rewrite of the original createNewHub function from src/utils/phoenix-utils.js.
 * There are no arguments and the value returned by this function is not the url anymore, but the whole hub object.
 */
export async function createNewHub() {
  const createUrl = getReticulumFetchUrl("/api/v1/hubs");
  const payload = { hub: { name: generateHubName() } };

  const headers = { "content-type": "application/json" };
  const store = new Store();
  if (store.state && store.state.credentials.token) {
    headers.authorization = `bearer ${store.state.credentials.token}`;
  }

  let res = await fetch(createUrl, {
    body: JSON.stringify(payload),
    headers,
    method: "POST"
  }).then(r => r.json());

  if (res.error === "invalid_token") {
    // Clear the invalid token from store.
    store.update({ credentials: { token: null, email: null } });

    // Create hub anonymously
    delete headers.authorization;
    res = await fetch(createUrl, {
      body: JSON.stringify(payload),
      headers,
      method: "POST"
    }).then(r => r.json());
  }

  const hub = res;

  const creatorAssignmentToken = hub.creator_assignment_token;
  if (creatorAssignmentToken) {
    store.update({ creatorAssignmentTokens: [{ hubId: hub.hub_id, creatorAssignmentToken: creatorAssignmentToken }] });

    // Don't need to store the embed token if there's no creator assignment token, since that means
    // we are the owner and will get the embed token on page load.
    const embedToken = hub.embed_token;

    if (embedToken) {
      store.update({ embedTokens: [{ hubId: hub.hub_id, embedToken: embedToken }] });
    }
  }

  if (isLocalClient()) {
    hub.url = `/hub.html?hub_id=${hub.hub_id}`;
  }

  return hub;
}

export { isLocalClient } from "../../../utils/phoenix-utils";
