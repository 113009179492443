import axios from "axios";
import { drmTypes } from "../video-utils";
import { getOrCreateDevice } from "./m10-utils";
import { VsmErrorCodes, WwmError } from "../../app/libs/errors/wwm-errors";

export class MinervaClient {
  //EDGE
  async provisionCredentials(edgeBasePath, username, password) {
    const device = JSON.parse(getOrCreateDevice());
    return await axios.post(`${edgeBasePath}/xtv-ws-client/api/v1/provision/credentials`, {
      username,
      password,
      type: "accountid",
      deviceInfo: device.deviceInfo,
      identifier: device.identifier
    });
  }

  async session(edgeBasePath, username, deviceToken) {
    const device = JSON.parse(getOrCreateDevice());
    return await axios.post(`${edgeBasePath}/xtv-ws-client/api/v1/session/${username}`, {
      deviceInfo: device.deviceInfo,
      deviceToken
    });
  }

  async customer(edgeBasePath, hostToken) {
    return await axios.get(`${edgeBasePath}/xtv-ws-client/api/v1/customer`, {
      headers: {
        Authorization: `Bearer ${hostToken}`
      }
    });
  }

  async multirightsDevices(edgeBasePath, hostToken) {
    return await axios.post(`${edgeBasePath}/xtv-ws-client/api/multirights/devices`, null, {
      headers: {
        Authorization: `Bearer ${hostToken}`
      }
    });
  }

  //PRM REGISTER
  async register(prmBasePath, body, hostToken, mnCustomer) {
    return await axios.post(`${prmBasePath}/policy_manager/v4/register`, body, {
      headers: {
        Authorization: `Bearer ${hostToken}`,
        ["mn-customer"]: mnCustomer
      }
    });
  }

  // PRM
  async contentSource(prmContentSourceUrl, contentType, params) {
    contentType = contentType.toUpperCase() === "SINGLE_RECORDING" ? "RECORDING" : contentType;
    const response = await axios.get(`${prmContentSourceUrl}/${contentType}/`, {
      params: params
    });
    // TODO: check if playback_resources array can have more than 1 element.
    const { playback_resources } = response.data;
    const errorCode = playback_resources[0]?.errorCode;
    if (errorCode) {
      const errorMessage = mapContentSourceErrorCode(errorCode);
      console.error("Error getting content source: ", playback_resources[0]);
      throw new Error(errorMessage);
    }
    return response;
  }

  async concurrency(prmConcurrencyCheckUrl, body, head) {
    try {
      return await axios.post(prmConcurrencyCheckUrl, body, {
        headers: {
          ...head
        }
      });
    } catch (err) {
      console.log("Error on concurrency api: ", err);
    }
  }
}

export async function buildHostVideoSource(
  contentId,
  contentType,
  hostToken,
  edgeBasePath,
  prmBasePath,
  drmType,
  attempt = 0
) {
  try {
    const minervaClient = new MinervaClient();

    async function requestCustomerHeader() {
      const res = await minervaClient.customer(edgeBasePath, hostToken);
      const headers = {
        ["mn-services"]: res.headers["mn-services"],
        ["mn-packages"]: res.headers["mn-packages"],
        ["mn-customer"]: res.headers["mn-customer"]
      };
      return headers;
    }

    const headers = await requestCustomerHeader();

    const multirightsDevicesRes = await minervaClient.multirightsDevices(edgeBasePath, hostToken);
    const { device } = multirightsDevicesRes.data;
    const deviceCasId = device.vuId;
    // TODO: check if the device is good also for Safari host.
    const cloudDevice = {
      deviceType: "cloud_client",
      networkType: "Broadband",
      playerType: drmType ? `Webplayer-${drmType}` : "Webplayer",
      deviceCasId
    };
    const registerResponse = await minervaClient.register(prmBasePath, cloudDevice, hostToken, headers["mn-customer"]);
    const { prmHosts, deviceInfoToken } = registerResponse.data;
    const drmHeaders = {
      ["mn-prm-session"]: registerResponse.headers["mn-prm-session"],
      ["mn-device-filters"]: deviceInfoToken,
      ["mn-services"]: headers["mn-services"],
      ["mn-packages"]: headers["mn-packages"]
    };

    const params = {
      serviceSessionId: headers["mn-services"],
      deviceInfoToken: drmHeaders["mn-device-filters"],
      contentId
    };
    const contentSourceResponse = await minervaClient.contentSource(prmHosts.contentSourceUrl, contentType, params);
    const { playback_resources } = contentSourceResponse.data;

    const concurrencyHeader = {
      Authorization: "Bearer " + hostToken
    };
    // TODO: check if playback_resources array can have more than 1 element.
    return await buildVideoSrc(minervaClient, playback_resources[0], drmHeaders, prmHosts, concurrencyHeader);
  } catch (err) {
    if (attempt < 10) {
      const retry = attempt + 1;
      console.warn(`Error on buildHostVideoSource retry number ${retry}`);
      return await buildHostVideoSource(contentId, contentType, hostToken, edgeBasePath, prmBasePath, drmType, retry);
    }
    console.error(err.message);
    throw new WwmError(err.message, VsmErrorCodes.HOST_VIDEO_SOURCE_BUILDING);
  }
}

export async function buildViewerVideoSource(contentId, contentType, deviceInfo) {
  const minervaClient = new MinervaClient();
  let sendDeviceInfoRes;
  try {
    sendDeviceInfoRes = await window.APP.wwmClient.sendDeviceInfo(deviceInfo);
  } catch (err) {
    console.error("Error the host cannot register your device: ", err.message);
    throw new WwmError(err.message, VsmErrorCodes.VIEWER_DEVICE_REGISTRATION);
  }

  if (sendDeviceInfoRes.error) {
    throw new WwmError(sendDeviceInfoRes.error, VsmErrorCodes.VIEWER_DEVICE_REGISTRATION);
  }

  const params = {
    serviceSessionId: sendDeviceInfoRes["mn-services"],
    deviceInfoToken: sendDeviceInfoRes["mn-device-filters"],
    contentId
  };
  const { prmHosts } = sendDeviceInfoRes.prmResponse;
  const contentSourceResponse = await minervaClient.contentSource(prmHosts.contentSourceUrl, contentType, params);
  const { playback_resources } = contentSourceResponse.data;

  const drmHeaders = {
    ["mn-prm-session"]: sendDeviceInfoRes["mn-prm-session"],
    ["mn-device-filters"]: sendDeviceInfoRes["mn-device-filters"],
    ["mn-services"]: sendDeviceInfoRes["mn-services"],
    ["mn-packages"]: sendDeviceInfoRes["mn-packages"]
  };
  const concurrencyHeader = {
    ["mn-prm-session"]: sendDeviceInfoRes["mn-prm-session"]
  };
  // TODO: check if playback_resources array can have more than 1 element.
  return await buildVideoSrc(minervaClient, playback_resources[0], drmHeaders, prmHosts, concurrencyHeader);
}

async function buildVideoSrc(minervaClient, playbackResource, drmHeaders, prmHosts, concurrencyHeader) {
  const playbackResourceToken = playbackResource.playbackResourceToken;
  const encryption = playbackResource.encryption?.toLowerCase();
  let videoSource = {
    [playbackResource.protocol.toLowerCase()]: playbackResource.contentUrl
  };

  if (encryption && encryption !== "none") {
    let drmConfig = {
      LA_URL: playbackResource.licenseUrl || `${prmHosts.drmProxyUrl}/${encryption}`,
      headers: {
        ["mn-playback"]: playbackResourceToken,
        ...drmHeaders
      }
    };

    if (encryption === drmTypes.FAIRPLAY) {
      drmConfig.useUint16InitData = true;
      drmConfig.certificateURL = playbackResource.certificateUrl || `${prmHosts.certificatesUrl}/${encryption}`;
      drmConfig.licenseResponseType = "arraybuffer";
      drmConfig.headers["Content-Type"] = "application/octet-stream";
      drmConfig.prepareContentId = url => {
        return /.+skd:\/\/(.+)/.exec(url)[1];
      };
      drmConfig.prepareMessage = event => {
        return event.message;
      };
      drmConfig.prepareLicense = license => {
        return new Uint8Array(license);
      };
    }
    videoSource.drm = { [encryption]: drmConfig };
  }

  if (playbackResource.concurrency) {
    const body = {
      status: "play",
      playbackResourceToken
    };
    await minervaClient.concurrency(prmHosts.concurrencyCheckUrl, body, concurrencyHeader);
  }
  return videoSource;
}

function mapContentSourceErrorCode(errorCode) {
  let errorMessage = "";
  switch (errorCode) {
    case 404:
      //No matching playback resource in DB for that contentId.
      errorMessage = "Content not available.";
      break;
    case 461:
      //Incompatible device type / no matching result found.
      errorMessage = "Incompatible device type.";
      break;
    case 462:
      //Geo blocking
      errorMessage = "Content not available from your current location.";
      break;
    case 463:
      errorMessage = "Missing proper service assignment.";
      break;
    case 464:
      errorMessage = "Content not available on the current client network type.";
      break;
    default:
      errorMessage = "An error occurred please try reload page.";
      break;
  }
  return errorMessage;
}

export async function buildDeviceRegistrationResponse(
  deviceRegistrationRequest,
  edgeBasePath,
  hostToken,
  prmBasePath,
  attempt = 0
) {
  try {
    const minervaClient = new MinervaClient();
    const customerResponse = await minervaClient.customer(edgeBasePath, hostToken);
    const multirightsDevicesRes = await minervaClient.multirightsDevices(edgeBasePath, hostToken);
    const { device } = multirightsDevicesRes.data;
    const deviceCasId = device.vuId;
    const registerResponse = await minervaClient.register(
      prmBasePath,
      {
        ...deviceRegistrationRequest.deviceInfo,
        deviceIpToken: deviceRegistrationRequest.deviceIpToken,
        deviceCasId
      },
      hostToken,
      customerResponse.headers["mn-customer"]
    );
    return {
      prmResponse: registerResponse.data,
      ["mn-device-filters"]: registerResponse.data.deviceInfoToken,
      ["mn-prm-session"]: registerResponse.headers["mn-prm-session"],
      ["mn-services"]: customerResponse.headers["mn-services"],
      ["mn-packages"]: customerResponse.headers["mn-packages"]
    };
  } catch (err) {
    if (attempt < 10) {
      const retry = attempt + 1;
      console.warn(`Error on buildDeviceRegistrationResponse retry number ${retry}`);
      return await buildDeviceRegistrationResponse(
        deviceRegistrationRequest,
        edgeBasePath,
        hostToken,
        prmBasePath,
        retry
      );
    }
    throw new WwmError(err.message, VsmErrorCodes.VIEWER_DEVICE_REGISTRATION);
  }
}
