import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import configs from "../../utils/configs";
import styles from "./WwmRoomCreator.scss";
import { AuthContext } from "../auth/AuthContext";
import { Container } from "../layout/Container";
import { Column } from "../layout/Column";
import { Row } from "../layout/Row";
import { LoadingScreen } from "../room/LoadingScreen";
import { validateStreamUrl } from "../../wwm/utils/url-utils";
import { createAndInitializeWwmClient } from "../../wwm/wwm-client";
import { timedPromise } from "../../wwm/utils/async-utils";
import { createNewHub, isLocalClient } from "../../wwm/utils/hubs/phoenix-utils";
import { compressContentObject, decompressContentObject } from "../../wwm/utils/compress-utils";
import { WwmRoomTypes } from "@toktv/wwm-sdk";
import { MetacastPayload } from "../../wwm/utils/metacast-utils";
import { MetacastErrorCodes, WwmError } from "../../wwm/app/libs/errors/wwm-errors";
import { ErrorScreenModal } from "../../wwm/app/components/system/ErrorScreenModal";
import useAppStore from "../../wwm/app/store/core/app-store";

export function WwmRoomCreator({ hostToken, videoSrc, metacast, toHubs = true }) {
  const auth = useContext(AuthContext);
  const error = useAppStore(state => state.error);

  //TODO: No localization here
  const MSG = "Creating Room...";
  const logoSrc = configs.image("logo");

  useEffect(() => {
    let client;
    const createWwmRoomAndRedirectToNewHub = async () => {
      try {
        MetacastPayload.parse().validate();
      } catch (err) {
        throw new WwmError(err.message, MetacastErrorCodes.INVALID_METACAST_PAYLOAD);
      }

      const wwmToken = hostToken.trim();
      let streamUrl = videoSrc.hls || videoSrc.dash;
      if (streamUrl) {
        streamUrl = streamUrl.trim();
        await validateStreamUrl(streamUrl);
      }

      const userProps = MetacastPayload.parse().getUserProps();
      client = createAndInitializeWwmClient({ token: wwmToken, userProps });
      await client.connect();
      const roomType = toHubs ? WwmRoomTypes.ROOM_3D : WwmRoomTypes.ROOM_2D;
      const oldRoom = await client.getRoomIfExists({ roomType, edge: userProps?.edge });

      let shortUrl;
      let hubUrl;
      let roomId;
      let hostLeid;

      if (!oldRoom.room) {
        const hub = await createNewHub();
        console.log("[HUB]: ", hub);
        hubUrl = hub.url;
        let viewerBaseUrl;

        if (toHubs) {
          viewerBaseUrl = isLocalClient() ? location.origin + hubUrl : hubUrl;
        } else {
          hubUrl = viewerBaseUrl = location.origin + `?hub_id=${hub.hub_id}`;
        }

        const createRes = await client.createRoom({ viewerBaseUrl, roomType, edge: userProps?.edge }); // Save url on wwm roomController
        console.log("WWM MQTT Received response for room creation: " + JSON.stringify(createRes));
        shortUrl = createRes.room.shortUrl;
        roomId = createRes.room.roomId;
        hostLeid = createRes.room.hostLeid;
      } else {
        console.log("WWM MQTT Received old room response: " + JSON.stringify(oldRoom));
        hubUrl = oldRoom.room.viewerUrl;
        shortUrl = oldRoom.room.shortUrl;
        roomId = oldRoom.room.roomId;
        hostLeid = oldRoom.room.hostLeid;
      }
      await client.end();

      const metacastPayload = decompressContentObject(metacast);
      const updatedMetacast = compressContentObject({
        ...metacastPayload,
        shortUrl,
        roomId,
        hostLeid
      });
      document.location =
        (/[^\s?]+\?/.test(hubUrl) ? `${hubUrl}&` : `${hubUrl}?`) + `metacast=${encodeURIComponent(updatedMetacast)}`;
    };

    timedPromise(
      createWwmRoomAndRedirectToNewHub(),
      15 * 1000,
      new Error("Time out while requesting TOK Room creation.")
    ).catch(async err => {
      console.error("WWM ROOM CREATOR:", err);
      if (err instanceof WwmError) {
        useAppStore.getState().setErrorMessage(null, err.getIntlError());
      } else {
        useAppStore.getState().setErrorMessage(err.message);
      }
      await client?.end();
    });
  }, []);

  return (
    <Container>
      <Row>
        <Column center padding>
          {error ? (
            <ErrorScreenModal />
          ) : (
            <LoadingScreen
              logoSrc={configs.image("logo")}
              message={MSG}
              infoMessages={[{ heading: "", message: "" }]}
            />
          )}
        </Column>
      </Row>
    </Container>
  );
}

WwmRoomCreator.propTypes = {
  hostToken: PropTypes.string.isRequired,
  videoSrc: PropTypes.object.isRequired,
  metacast: PropTypes.string.isRequired
};
