import { deflate, inflate } from "pako";

export function compressContentObject(payload) {
  if (typeof payload !== "object") {
    throw new Error("The argument must be an object");
  }

  const payloadStringified = JSON.stringify(payload);

  const transmittedPayload = encodeURIComponent(Buffer.from(deflate(payloadStringified)).toString("base64"));

  return transmittedPayload;
}

export function decompressContentObject(payload) {
  if (typeof payload !== "string") {
    throw new Error("The argument must be a string");
  }

  const uriDecodedString = decodeURIComponent(payload);

  const transmittedPayload = JSON.parse(inflate(Buffer.from(uriDecodedString, "base64"), { to: "string" }));

  return transmittedPayload;
}
