import { qsFromEntries } from "../../utils/qs_truthy";
import { decompressContentObject } from "./compress-utils";
//import { WwmRoomTypes } from "@toktv/wwm-sdk"; NOTE: DO NOT USE WwmRoomTypes from SDK in this file. It will break `npm run login`
const WwmRoomTypes = Object.freeze({
  ROOM_2D: "2D",
  ROOM_3D: "3D"
});

import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true, useDefaults: true });

const roomCreatorSchema = {
  $id: "roomCreatorSchema",
  type: "object",
  properties: {
    provider: {
      type: "string",
      enum: ["minerva", "metacast"]
    },
    version: {
      type: "number",
      enum: [10]
    },
    hostToken: {
      type: "string"
    },
    roomType: {
      type: "string",
      enum: [WwmRoomTypes.ROOM_2D, WwmRoomTypes.ROOM_3D]
    },
    videoSrc: {
      type: "object"
    },
    userProps: {
      type: "object"
    }
  },
  required: ["provider", "hostToken", "roomType", "videoSrc"],
  if: {
    properties: { provider: { const: "minerva" } }
  },
  then: {
    properties: {
      userProps: {
        type: "object",
        properties: {
          edge: {
            type: "string"
          },
          prmv4: {
            type: "string"
          }
        },
        required: ["edge", "prmv4"]
      }
    },
    required: ["version", "userProps"]
  },
  additionalProperties: true
};

const appSchema = {
  type: "object",
  allOf: [
    { $ref: "roomCreatorSchema" },
    {
      properties: {
        shortUrl: { type: "string" },
        roomId: { type: "string" },
        hostLeid: { type: "string" }
      },
      required: ["shortUrl", "roomId", "hostLeid"]
    }
  ]
};
const validateRoomCreator = ajv.compile(roomCreatorSchema);
const validateApp = ajv.compile(appSchema);

/**
 * @deprecated
 */
export function getMetacastPayload() {
  let metacastPayload = {};
  const metacast = qsFromEntries().metacast;
  if (metacast) {
    metacastPayload = decompressContentObject(metacast);
  }
  return metacastPayload;
}

/**
 * @deprecated
 */
export function getMetacastDisplayName() {
  return getMetacastPayload()?.displayName;
}

/**
 * @deprecated
 */
export function getMetacastChannel() {
  return getMetacastPayload()?.channel;
}

/**
 * @deprecated
 */
export function getMetacastHostToken() {
  return getMetacastPayload()?.hostToken;
}

/**
 * @deprecated
 */
export function getMetacastRoomId() {
  return getMetacastPayload()?.roomId;
}

/**
 * @deprecated
 */
export function getMetacastRoomInfo() {
  const payload = getMetacastPayload();
  return {
    channel: payload?.channel,
    public: payload?.isPublic,
    hostId: payload?.hostId
  };
}

/**
 * @deprecated
 */
export function getMetacastProgramMetadata() {
  const payload = getMetacastPayload();
  return payload?.programMetadata;
}

/**
 * @deprecated
 */
export function getMetacastUserProps() {
  const payload = getMetacastPayload();
  return payload?.userProps;
}

/**
 * @deprecated
 */
export function getMetacastInitialPosition() {
  const payload = getMetacastPayload();
  return payload?.initialPosition;
}

export const SchemaTypes = Object.freeze({
  ROOM_CREATOR: "roomCreator",
  APP: "app"
});

function validate(payload, options) {
  const type = options.type;
  let validator;
  switch (type) {
    case SchemaTypes.APP:
      validator = validateApp;
      break;
    default:
      validator = validateRoomCreator;
      break;
  }
  const valid = validator(payload);
  if (!valid) {
    console.error("Invalid: ", ajv.errorsText(validator.errors));
    throw new Error("INVALID_METACAST_PAYLOAD");
  }
}

export const MetacastPayload = {
  parse: function() {
    const qs = new URLSearchParams(location.search);
    let payloadParam = qs.get("metacast");
    let payload = payloadParam ? decompressContentObject(payloadParam) : {};

    return {
      getCompressed: () => payloadParam,
      get: () => payload,
      getHostToken: () => payload.hostToken,
      getRoomInfo: () => ({
        channel: payload?.channel,
        public: payload?.isPublic,
        hostId: payload?.hostId
      }),
      getShortUrl: () => payload.shortUrl,
      getDisplayName: () => payload.displayName,
      getProgramMetadata: () => payload.programMetadata,
      getUserProps: () => payload.userProps,
      getVideoInitialPosition: () => payload.initialPosition,
      //
      getRole: () => payload.role,
      getRoomId: () => payload.roomId,
      getVideoSrc: () => payload.videoSrc,
      validate: (options = { type: SchemaTypes.ROOM_CREATOR }) => validate(payload, options)
    };
  }
};
