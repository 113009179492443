import React, { useCallback, useState } from "react";
import { useForm } from "@mantine/form";
import { TextInput, Button, Group, createStyles, Stack, PasswordInput, Select, rem, Switch } from "@mantine/core";
import { MinervaClient } from "../../utils/minerva-integration/minerva-client";
import { Loader } from "../../hubsUI/components/loaders/Loader";
import Alert from "../../../react-components/tok-components/Alert";
import { createWwmRoomAndRedirectToNewHub } from "../../utils/minerva-integration/signin-utils";

const users = [
  {
    label: "gdarrigo",
    value: "gdarrigo",
    key: "2262",
    username: "2262",
    password: "0",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "capo",
    value: "capo",
    key: "126",
    username: "126",
    password: "0",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "msalafia",
    value: "msalafia",
    key: "2263",
    username: "2263",
    password: "0",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "atuzzi",
    value: "atuzzi",
    key: "2264",
    username: "2264",
    password: "0",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "mzonca",
    value: "mzonca",
    key: "2266",
    username: "2266",
    password: "0",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "Gabriel",
    value: "Gabriel",
    key: "1067",
    username: "1067",
    password: "1",
    edge: "https://m10tv.com:4446"
  },
  {
    label: "gdarrigo-PVU",
    value: "gdarrigo-PVU",
    key: "305",
    username: "305",
    password: "0",
    edge: "https://edge.pvu.yourtvnow.tv:4446"
  }
];

const useStyles = createStyles((theme, params) => ({
  form: {
    minWidth: rem(350)
  }
}));

export function MinervaAuthForm() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      is3D: false,
      username: "",
      password: "",
      edge: "https://m10tv.com:4446",
      streamUrl: "https://media.tok.tv/minerva/STORAGE/a_live/shows/ronaldino_hls/playlist.m3u8",
      licenseUrl: ""
    },
    validate: {
      username: value => (value ? null : "Invalid username"),
      password: value => (value ? null : "Invalid password")
    }
  });

  const onSelect = useCallback(
    value => {
      const selected = users.find(user => user.value === value);
      form.setValues(() => ({
        value: null,
        username: selected.username,
        password: selected.password,
        edge: selected.edge
      }));
    },
    [form]
  );

  const onSubmit = useCallback(async values => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const { is3D, username, password, edge, streamUrl, licenseUrl } = values;

      console.log("is3D:", is3D);

      const minervaClient = new MinervaClient();
      const credentialsResponse = await minervaClient.provisionCredentials(edge, username, password);
      const sessionResponse = await minervaClient.session(edge, username, credentialsResponse.data.deviceToken);
      const token = sessionResponse?.headers?.authorization.replace("Bearer ", "");
      const minervaEndpoints = sessionResponse?.data?.endPoints;
      const data = {
        streamUrl,
        licenseUrl,
        token,
        minervaEndpoints
      };
      await createWwmRoomAndRedirectToNewHub(data, is3D, message => {
        setErrorMessage(message);
        setLoading(false);
      });
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setErrorMessage("Error creating room check signin parameters.");
      setLoading(false);
    }
  }, []);

  return (
    <Stack>
      {loading ? (
        <Loader label="Creating Room..." />
      ) : (
        <form className={classes.form} onSubmit={form.onSubmit(onSubmit)}>
          <Switch
            size="md"
            mb="md"
            label="Open in TOK 3D or TOK 2D"
            onLabel="3D"
            offLabel="2D"
            {...form.getInputProps("is3D", { type: "checkbox" })}
          />
          <Select
            label="Select a user"
            placeholder="Select a user..."
            value={null}
            data={users}
            onChange={onSelect}
            selectOnBlur={false}
          />
          <TextInput
            label="Username"
            placeholder="Username"
            {...form.getInputProps("username")}
            required
            withAsterisk
          />
          <PasswordInput
            autoComplete="off"
            visible={false}
            label="Password"
            placeholder="Password"
            {...form.getInputProps("password")}
            required
            withAsterisk
          />
          <TextInput label="Edge" placeholder="Edge basePath" {...form.getInputProps("edge")} required withAsterisk />
          <TextInput label="Stream URL" placeholder="Enter a stream url" {...form.getInputProps("streamUrl")} />
          <TextInput label="License URL" placeholder="Enter a license url" {...form.getInputProps("licenseUrl")} />
          <Group position="center" mt="md">
            <Button type="submit">Apply</Button>
          </Group>
        </form>
      )}
      {errorMessage && <Alert>{errorMessage}</Alert>}
    </Stack>
  );
}
