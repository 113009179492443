import React from "react";
import styles from "./Alert.scss";

const Alert = ({ children, style, title }) => {
  return (
    <div className={styles.alert} style={style}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};

export default Alert;
