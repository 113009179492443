import { produce } from "immer";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

const useMinervaStore = create(
  devtools(
    subscribeWithSelector(set => ({
      programMetadata: null,
      setProgramMetadata: programMetadata =>
        set(
          produce(state => {
            state.programMetadata = programMetadata;
          })
        )
    })),
    { name: "minervaStore", enabled: true }
  )
);

export default useMinervaStore;
