import { WwmErrorCodes } from "@toktv/wwm-sdk";

export const VsmErrorCodes = Object.freeze({
  HOST_DEVICE_REGISTRATION: "hostDeviceRegistration",
  HOST_VIDEO_SOURCE_BUILDING: "hostVideoSourceBuilding",
  VIEWER_DEVICE_REGISTRATION: "viewerDeviceRegistration"
});

export const MetacastErrorCodes = Object.freeze({
  INVALID_METACAST_PAYLOAD: "invalidMetacastPayload"
});

export class WwmError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
    this.name = "WwmError";
  }

  getIntlError() {
    switch (this.code) {
      //sdk
      case WwmErrorCodes.INVALID_ROLE:
        return {
          messageId: "metacast.error-screen-modal.errors.join-invalid-role",
          defaultMessage: "Client is trying to join a room with an invalid role"
        };
      case WwmErrorCodes.ROOM_NOT_EXISTS:
        return {
          messageId: "metacast.error-screen-modal.errors.join-room-not-exists",
          defaultMessage: "Client is tying to join a room that does not exist"
        };
      case WwmErrorCodes.ROOM_NOT_OWNED:
        return {
          messageId: "metacast.error-screen-modal.errors.join-room-not-owned",
          defaultMessage: "Host is trying to join a room that it does not own"
        };
      case WwmErrorCodes.REACHED_VIEWER_LIMIT:
        return {
          messageId: "metacast.error-screen-modal.errors.join-reached-viewer-limit",
          defaultMessage: "Viewer client cannot join the room, because the max number of viewers has been reached"
        };
      case WwmErrorCodes.VIEWER_LEID_NOT_EXISTS:
        return {
          messageId: "metacast.error-screen-modal.errors.join-viewer-leid-not-exists",
          defaultMessage: "Viewer companion client cannot join the room. Internal error: Logical Endpoint Id not found"
        };
      case WwmErrorCodes.VIEWER_COMPANION_NO_LEID:
        return {
          messageId: "metacast.error-screen-modal.errors.join-viewer-companion-no-leid",
          defaultMessage:
            "Viewer companion client cannot join the room. Internal error: it requires a Logical Endpoint Id"
        };
      case WwmErrorCodes.FULL_ALREADY_EXISTS:
        return {
          messageId: "metacast.error-screen-modal.errors.join-full-already-exists",
          defaultMessage: "Client cannot join the room, because it is already present (with FULL role)"
        };
      case WwmErrorCodes.ALREADY_EXISTS_TV:
        return {
          messageId: "metacast.error-screen-modal.errors.join-already-exists-tv",
          defaultMessage: "Client cannot join the room, because an active TV client is already present"
        };
      case WwmErrorCodes.COMPANION_ALREADY_EXISTS:
        return {
          messageId: "metacast.error-screen-modal.errors.join-companion-already-exists",
          defaultMessage: "Companion client cannot join the room, because it is already present"
        };
      case WwmErrorCodes.COMPANION_NEEDS_TV:
        return {
          messageId: "metacast.error-screen-modal.errors.join-companion-needs-tv",
          defaultMessage: "Companion client cannot join the room, because it requires an active TV client"
        };
      //vsm
      case VsmErrorCodes.HOST_DEVICE_REGISTRATION:
        return {
          messageId: "metacast.vsm.errors.host-device-registration",
          defaultMessage: "Error on host device registration"
        };
      case VsmErrorCodes.HOST_VIDEO_SOURCE_BUILDING:
        return {
          messageId: "metacast.vsm.errors.building-video-source",
          defaultMessage: "Error on building video source"
        };
      case VsmErrorCodes.VIEWER_DEVICE_REGISTRATION:
        return {
          messageId: "metacast.vsm.errors.viewer-device-registration",
          defaultMessage: "Error the host cannot register your device"
        };
      case MetacastErrorCodes.INVALID_METACAST_PAYLOAD:
        return {
          messageId: "metacast.errors.invalid-metacast-payload",
          defaultMessage: "Invalid payload"
        };
      default:
        console.warn(`Error code ${this.code} not found using default.`);
        return {
          messageId: "metacast.vsm.errors.unexpected-error",
          defaultMessage: "An unexpected error occurred. Please try again later"
        };
    }
  }
}
