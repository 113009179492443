function decomposeTime(t) {
  let s = Math.floor(t),
    h = Math.floor(s / 3600);
  s -= h * 3600;
  let m = Math.floor(s / 60);
  s -= m * 60;

  return {
    hours: h,
    minutes: m,
    seconds: s
  };
}

export function timeFmt(t) {
  let { hours: h, minutes: m, seconds: s } = decomposeTime(t);

  if (h < 10) h = `0${h}`;
  if (m < 10) m = `0${m}`;
  if (s < 10) s = `0${s}`;
  return h === "00" ? `${m}:${s}` : `${h}:${m}:${s}`;
}

export function timeFmtAgainstDuration(t, d) {
  let { hours: dh } = decomposeTime(d);
  let { hours: h, minutes: m, seconds: s } = decomposeTime(t);

  if (h < 10) h = `0${h}`;
  if (m < 10) m = `0${m}`;
  if (s < 10) s = `0${s}`;

  return h === "00" && dh === 0 ? `${m}:${s}` : `${h}:${m}:${s}`;
}

export function attachMediaStream(element, stream) {
  try {
    element.srcObject = stream;
  } catch (e) {
    try {
      element.src = URL.createObjectURL(stream);
    } catch (e) {
      console.error("Error attaching stream to element", e);
    }
  }
}

export const drmTypes = Object.freeze({
  FAIRPLAY: "fairplay",
  PLAYREADY: "playready",
  WIDEVINE: "widevine"
});

export function getDrmType(playerSupportedDRM) {
  const drmTypesMap = {
    widevine: "widevine",
    fairplay: "apple.fps",
    playready: "playready"
  };
  const keys = Object.keys(drmTypesMap);
  const values = Object.values(drmTypesMap);

  const supported = playerSupportedDRM
    .filter(val => val && typeof val === "string")
    .map(current => {
      const index = values.findIndex(mapValue => current.indexOf(mapValue) !== -1);
      return keys[index];
    })
    .filter(val => val);
  return supported.shift();
}
