/* 
* This file provides the language options for TOK 2D. 
* The main purpose is:
* - derive the INITIAL language and locale on the basis of the browser/system language
* - provide the list of available languages
* - provide the options for the relevant settings selector
*
* In order to Add a new language, follow these steps:
*   1. Add the .json file inside the folder src/wwm/app/i18n/lang
*   2. Import the file at the top of this file declaring a capitalised variable name with the relevant language name
*   3. Add the language to the AVAILABLE_LANGUAGES object using as key the standard language code (e.g. en, it, fr, etc.) - no region code
*   4. Add the language to the AVAILABLE_LANGUAGES_OPTIONS object using as value the standard language code (e.g. en, it, fr, etc.) and as label the language name
*
* The steps are highlighted in the code below.
*/

// 2. Import the file at the top of this file declaring a capitalised variable name with the relevant language name
import English from "./lang/en.json";
import Italian from "./lang/it.json";

// 3. Add the language to the AVAILABLE_LANGUAGES object using as key the standard language code (e.g. en, it, fr, etc.) - no region code
const AVAILABLE_LANGUAGES = {
  en: English,
  it: Italian
};

const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.en;

/* 
* WARNING: Do not use these variables in your code to retrieve locale. They are not reactive.
* They are used only to set the initial locale and language.
* Please, refer to the settings store to retrieve the actual variable state for the locale.
*/
export const local = navigator.language.toLowerCase().split(/[_-]+/)[0];
export const lang = AVAILABLE_LANGUAGES[local] || DEFAULT_LANGUAGE;

export function getMessagesFromLocale(locale) {
  return AVAILABLE_LANGUAGES[locale] || DEFAULT_LANGUAGE;
}

// 4. Add the language to the AVAILABLE_LANGUAGES_OPTIONS object using as value the standard language code (e.g. en, it, fr, etc.) and as label the language name
export const AVAILABLE_LANGUAGES_OPTIONS = [{ value: "en", label: "English" }, { value: "it", label: "Italiano" }];
