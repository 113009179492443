import React from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import useSettingsStore from "../store/core/settings-store";

export function MetacastIntlProvider({ children }) {
  const locale = useSettingsStore(state => state.general.local);
  const messages = useSettingsStore(state => state.general.lang);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
