import { Box, createStyles, Text, Title } from "@mantine/core";
import React from "react";

const useStyles = createStyles(theme => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export function BoundaryErrorScreen({ error }) {
  const { classes, theme } = useStyles();

  return (
    <Box className={classes.root}>
      <Title order={2}>Error loading TOK</Title>
      <Text color="red">{error.message}</Text>
    </Box>
  );
}
