import { produce } from "immer";
import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { getMessagesFromLocale, lang, local } from "../../i18n/i18n-utils";

const deepMerge = (firstObj, secondObj) => {
  const isObject = value => {
    return typeof value === "object" && value !== null;
  };

  if (!isObject(firstObj) || !isObject(secondObj)) {
    if (isObject(firstObj)) {
      return firstObj;
    } else if (isObject(secondObj)) {
      return secondObj;
    } else {
      return {};
    }
  }

  const merged = { ...firstObj };

  Object.keys(secondObj).forEach(key => {
    if (typeof secondObj[key] === "object") {
      if (!(key in firstObj)) {
        Object.assign(merged, { [key]: secondObj[key] });
      } else {
        merged[key] = deepMerge(firstObj[key], secondObj[key]);
      }
    } else {
      Object.assign(merged, { [key]: secondObj[key] });
    }
  });

  return merged;
};

const useSettingsStore = create(
  devtools(
    persist(
      subscribeWithSelector(set => ({
        general: {
          local: local,
          lang: lang
        },
        audio: {
          volumeLevels: {
            mediaVideo: 0.4,
            voiceChat: 1,
            sfx: 0.2
          }
        },
        notifications: {
          publicEnabled: true
        },
        /* Actions */
        // General
        setLocal: local => {
          set(
            produce(draft => {
              if (typeof local === "string") {
                draft.general.local = local;
                draft.general.lang = getMessagesFromLocale(local);
              }
            })
          );
        },
        // Audio
        setMediaVideoVolume: volume => {
          set(
            produce(draft => {
              if (typeof volume === "number") {
                draft.audio.volumeLevels.mediaVideo = volume;
              }
            })
          );
        },
        setVoiceChatVolume: volume => {
          set(
            produce(draft => {
              if (typeof volume === "number") {
                draft.audio.volumeLevels.voiceChat = volume;
              }
            })
          );
        },
        setSfxVolume: volume => {
          set(
            produce(draft => {
              if (typeof volume === "number") {
                draft.audio.volumeLevels.sfx = volume;
              }
            })
          );
        },
        // Notifications
        setPublicNotificationsEnabled: enabled => {
          set(
            produce(draft => {
              if (typeof enabled === "boolean") {
                draft.notifications.publicEnabled = enabled;
              }
            })
          );
        }
      })),
      {
        name: "__metacast2d_settings",
        partialize: state =>
          produce(state, draft => {
            delete draft.general.lang;
          }),
        merge: (persistedState, currentState) => {
          const merged = deepMerge(currentState, persistedState);
          merged.general.lang = getMessagesFromLocale(merged.general.local);
          return merged;
        }
      }
    ),
    { name: "settingsStore", enabled: true }
  )
);

export default useSettingsStore;
