import jwtDecode from "jwt-decode";

export function getIssuer(token) {
  if (token) {
    return jwtDecode(token)?.iss;
  }
  return "";
}

export function isMinerva(token) {
  if (token) {
    const decodedToken = jwtDecode(token);
    const hasAccountId = !!decodedToken.accountId;
    const hostIssuerMinerva = decodedToken?.hostIssuer === "minerva";
    return hasAccountId || hostIssuerMinerva;
  }
  return false;
}

export function getRoomId(token) {
  if (token) {
    return jwtDecode(token)?.roomId;
  }
}
