import { WwmClient } from "@toktv/wwm-sdk";
import { Subject, BehaviorSubject } from "rxjs";
import configs from "../utils/configs";
import { isMinerva } from "./utils/jwt-utils";
import useMinervaStore from "./store/minerva/minerva-store";

const defaultOptions = {
  customerEndpoint: configs.WWM_CUSTOMER_ENDPOINT,
  wwmEndpoint: configs.WWM_ENDPOINT,
  viewerBaseUrl: "viewer-base-url",
  connectTimeout: 10000,
  username: "WwmClient-mqtt-username",
  token: "",
  reconnectPeriod: 0,
  keepalive: 30,
  clean: true
};

export function createAndInitializeWwmClient(options) {
  const customerEndpoint =
    options.userProps && options.userProps.edge
      ? `${options.userProps.edge}/xtv-ws-client/api/v1/customer`
      : configs.WWM_CUSTOMER_ENDPOINT;

  const wwmOptions = { ...defaultOptions, ...options, customerEndpoint };
  const wwmClient = new WwmClient(wwmOptions);

  wwmClient.chatMessage$ = new Subject();
  wwmClient.sound$ = new Subject();
  wwmClient.videoSync$ = new Subject();
  wwmClient.background$ = new Subject();
  wwmClient.carousel$ = new BehaviorSubject([]);
  wwmClient.deviceInfo$ = new Subject();
  wwmClient.closeRoom$ = new Subject();
  wwmClient.reconnected$ = new Subject();
  wwmClient.metadata$ = new Subject();
  wwmClient.notification$ = new Subject();
  wwmClient.hostNotification$ = new Subject();

  wwmClient.onChatMessageReceived = res => {
    /* res: { sender: string, message: string } */
    wwmClient.chatMessage$.next(res);
  };

  wwmClient.onSoundReceived = res => {
    /* res: { soundUrl: string, iconUrl: string } */
    wwmClient.sound$.next(res);
  };

  wwmClient.onVideoSyncReceived = res => {
    wwmClient.videoSync$.next(res);
  };

  wwmClient.onBackgroundReceived = res => {
    /* res: { url: string} */
    wwmClient.background$.next(res);
  };

  wwmClient.onCarouselReceived = res => {
    wwmClient.carousel$.next(res);
  };

  if (wwmClient.isHost && isMinerva(options.token)) {
    wwmClient.onDeviceInfoReceived = async deviceRegistrationRequest => {
      let cb;
      const promise = new Promise((resolve, reject) => {
        cb = resolve;
      });
      wwmClient.deviceInfo$.next([deviceRegistrationRequest, cb]);
      return promise;
    };
  }

  wwmClient.onCloseRoomReceived = () => {
    wwmClient.closeRoom$.next();
  };

  wwmClient.onReconnectComplete = () => {
    wwmClient.reconnected$.next();
  };

  wwmClient.onProgramMetadataReceived = res => {
    wwmClient.metadata$.next(res);
    useMinervaStore.getState().setProgramMetadata(res.programMetadata);
  };

  wwmClient.onNotificationReceived = res => {
    wwmClient.notification$.next(res);
  };

  wwmClient.onHostNotificationReceived = res => {
    wwmClient.hostNotification$.next(res);
  };

  wwmClient.init();

  return wwmClient;
}

export const NotificationType = Object.freeze({
  STV: "stv",
  FULLSCREEN: "fullscreen",
  INLINE: "inline",
  TYPING: "start_typing",
  DISPLAY_NAME_UPDATED: "display_name_updated"
});

export const HostNotificationType = Object.freeze({
  HOST_COMMAND: "host_command"
});

export const HostCommands = Object.freeze({
  KICK_PARTICIPANT: "kick_participant"
});
