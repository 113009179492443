import { Box, createStyles, Loader, Title } from "@mantine/core";
import React from "react";
import { FormattedMessage } from "react-intl";

const useStyles = createStyles(theme => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export function SuspenseLoadingScreen() {
  const { classes, theme } = useStyles();

  return (
    <Box className={classes.root}>
      <Title order={2}>
        <FormattedMessage
          id="metacast.suspense-loading-screen-modal.loader.message.loading"
          defaultMessage="Loading TOK..."
        />
      </Title>
      <Loader mt="md" size="xl" variant="bars" color={theme.colors.hubsBlue[5]} />
    </Box>
  );
}
