import React, { useMemo } from "react";
import { Modal, Title, createStyles, Stack, rem } from "@mantine/core";

const useStyles = createStyles((theme, params) => ({
  root: {},
  content: {
    width: "auto",
    minWidth: rem(450),
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    transition: "all 1s ease-out"
  },
  header: {
    justifyContent: "center"
  },
  title: {
    color: theme.black
  },
  close: {
    position: "absolute",
    right: theme.spacing.md,
    color: theme.colors.gray[6],
    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[2]
    })
  },
  body: {
    color: theme.black
  }
}));

export function MinervaSignInModal({ content, ...others }) {
  const { classes } = useStyles();

  return (
    <Modal
      id="minerva-signin-modal"
      centered
      {...others}
      classNames={{
        root: classes.root,
        content: classes.content,
        header: classes.header,
        title: classes.title,
        close: classes.close,
        body: classes.body
      }}
      title="Sign In"
    >
      <Stack>{content}</Stack>
    </Modal>
  );
}
