/**
 * @param {Promise} requestPromise - The promise to be timed
 * @param {number} time - The timeout value
 * @param {Error} timeoutError - The error to be thrown after timeout
 */
export function timedPromise(requestPromise, time, timeoutError) {
  let timer;
  return Promise.race([
    requestPromise,
    new Promise((_, reject) => {
      timer = setTimeout(reject, time, timeoutError);
    })
  ]).finally(() => clearTimeout(timer));
}

// function wait for time
export function wait(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
