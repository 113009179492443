import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import Store from "./storage/store";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
//import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { WwmRoomCreator } from "./react-components/home/WwmRoomCreator";
import { decompressContentObject } from "./wwm/utils/compress-utils";
import { MantineProvider } from "@mantine/core";
import hubsUITheme from "./wwm/hubsUI/theme/hubs-ui-theme";
import { SuspenseLoadingScreen } from "./wwm/app/components/system/SuspenseLoadingScreen";
import hubsUIDarkTheme from "./wwm/hubsUI/theme/hubs-ui-dark-theme";
import { ErrorBoundary } from "react-error-boundary";
import { BoundaryErrorScreen } from "./wwm/app/components/system/BoundaryErrorScreen";
import ErrorPage from "./wwm/home/error/ErrorPage";
import { WwmRoomTypes } from "@toktv/wwm-sdk";
import { MetacastIntlProvider } from "./wwm/app/i18n/MetacastIntlProvider";

registerTelemetry("/home", "Hubs Home Page");

const store = new Store();
window.APP = { store };

const qs = new URLSearchParams(location.search);
let searchParamsObject = Object.fromEntries(qs);
let metacastPayload = {};

const metacast = searchParamsObject.metacast;
if (metacast) {
  metacastPayload = decompressContentObject(metacast);
}
const hub_id = searchParamsObject.hub_id;
const errorCode = searchParamsObject.errorCode;
/* the param metacast MUST contain "hostToken" and "streamUrl" to enable WwmRoomCreator*/
const showWwmCreator = metacastPayload.hostToken && metacastPayload.videoSrc;
const toHubs = !(metacastPayload.roomType === WwmRoomTypes.ROOM_2D);

const WwmApp = lazy(() => import("./wwm/app/wwm-app"));

const content3D = showWwmCreator ? (
  <MantineProvider theme={hubsUIDarkTheme} withGlobalStyles withNormalizeCSS>
    <MetacastIntlProvider>
      <WwmRoomCreator
        metacast={metacast}
        videoSrc={metacastPayload.videoSrc}
        hostToken={metacastPayload.hostToken}
        toHubs={toHubs}
      />
    </MetacastIntlProvider>
  </MantineProvider>
) : (
  <HomePage />
);

const content2D = (
  <MetacastIntlProvider>
    <MantineProvider theme={hubsUIDarkTheme} withGlobalStyles withNormalizeCSS>
      <ErrorBoundary FallbackComponent={BoundaryErrorScreen}>
        <Suspense fallback={<SuspenseLoadingScreen />}>
          <WwmApp />
        </Suspense>
      </ErrorBoundary>
    </MantineProvider>
  </MetacastIntlProvider>
);

const errorContent = (
  <MetacastIntlProvider>
    <MantineProvider theme={hubsUIDarkTheme} withGlobalStyles withNormalizeCSS>
      <ErrorPage code={errorCode} />
    </MantineProvider>
  </MetacastIntlProvider>
);

const content = errorCode ? errorContent : hub_id ? content2D : content3D;

function Root() {
  return (
    <MantineProvider theme={hubsUITheme}>
      <WrappedIntlProvider>
        <ThemeProvider store={store}>
          <AuthContextProvider store={store}>{content}</AuthContextProvider>
        </ThemeProvider>
      </WrappedIntlProvider>
    </MantineProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById("home-root"));
